export enum IdentificationType {
  /**
   * OP (občasnký průkaz)
   */
  IdentityCard = 1,
  /**
   * RP (řidičský průkaz)
   */
  DriversLicense = 2,
  /**
   * CP (cestovní pas)
   */
  Passport = 3,
  /**
   * PP (povolení k pobytu)
   */
  ResidencePermit = 4,
  /**
   * TP (povolení k trvalému pobytu)
   */
  PermanentResidencePermit = 5,
  /**
   * ZP (zbrojní průkaz)
   */
  GunLicense = 6,
  /**
   * OL (oddací list)
   */
  MarriageCertificate = 7,
  /**
   * RL (rodný list)
   */
  BirthCertificate = 8,
  Other = 9,
}
